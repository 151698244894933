import { gql } from '@apollo/client'

export const companyInformationQuery = gql`
  query CompanyInformationQuery($companyId: ID!) {
    # hasContracts
    lastContractDate(companyId: $companyId)

    # hasPositions
    positions(companyId: $companyId)
      @connection(key: "positionsInitialData", filter: ["companyId"]) {
      edges {
        node {
          id
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }

    #hasTeam
    team(companyId: $companyId, disabled: false)
      @connection(key: "teamInitialData", filter: ["companyId"]) {
      edges {
        node {
          company {
            id
          }
          employee {
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    #hasTeam2 (needed in case when user has only a disabled team member)
    teamWithDisabled: team(companyId: $companyId, disabled: true)
      @connection(key: "teamInitialData", filter: ["companyId"]) {
      edges {
        node {
          company {
            id
          }
          employee {
            id
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }

    # hasSurveys
    companySurveys(companyId: $companyId, state: ANY)
      @connection(
        key: "companySurveysInitialData"
        filter: ["companyId", "state"]
      ) {
      edges {
        node {
          createdAt
          employee {
            id
            firstName
            lastName
          }
          id
          participant {
            id
          }
          surveyType
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }

    # FAQ -> contact information (vivaldisOffice)
    user {
      id
      company {
        id
        vivaldisOffice {
          city
          code
          email
          name
          phone
          street
          zip
        }
      }
    }
  }
`
